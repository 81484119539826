import React, { useEffect, useState } from "react";
import { getFirestore, collection, getDocs, updateDoc, doc } from "firebase/firestore";
import { CSVLink } from "react-csv";
import app from "../firebase/firebase";
import { FaEdit, FaSave, FaTrash, FaDownload, FaPlus } from "react-icons/fa";
import { Tooltip } from "react-tooltip"; // Updated import

const db = getFirestore(app);

const JobApplications = () => {
    const [applications, setApplications] = useState([]);
    const [editingIndex, setEditingIndex] = useState(null);
    const [editedData, setEditedData] = useState({});

    useEffect(() => {
        const fetchApplications = async () => {
            const querySnapshot = await getDocs(collection(db, "applications"));
            const fetchedApplications = querySnapshot.docs.map((doc) => ({
                id: doc.id,
                ...doc.data(),
            }));
            setApplications(fetchedApplications);
        };

        fetchApplications();
    }, []);

    const formatWorkExperience = (workExperience) => {
        if (!workExperience || !Array.isArray(workExperience)) return "";
        return workExperience
            .map((work) => `${work.placeOfWork} (${work.startYear} - ${work.endYear})`)
            .join("; ");
    };

    const formatDateTime = (date) => {
        if (!date) return "";
        const parsedDate = new Date(date.seconds ? date.seconds * 1000 : date);
        return parsedDate.toLocaleString("en-US", {
            year: "numeric",
            month: "long",
            day: "numeric",
            hour: "2-digit",
            minute: "2-digit",
            second: "2-digit",
            hour12: true,
        });
    };


    const csvData = applications.map((application) => ({
        ...application,
        submittedAt: formatDateTime(application.submittedAt),
        workExperience: formatWorkExperience(application.workExperience),
    }));

    const handleEdit = (index) => {
        setEditingIndex(index);
        setEditedData({ ...applications[index] });
    };

    const handleSave = async () => {
        try {
            const docRef = doc(db, "applications", editedData.id);
            await updateDoc(docRef, editedData);
            const updatedApplications = [...applications];
            updatedApplications[editingIndex] = editedData;
            setApplications(updatedApplications);
            setEditingIndex(null);
            alert("Application updated successfully!");
        } catch (error) {
            console.error("Error updating application:", error);
            alert("Failed to update application.");
        }
    };

    const handleCancel = () => {
        setEditingIndex(null);
        setEditedData({});
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setEditedData((prev) => ({ ...prev, [name]: value }));
    };

    const handleWorkExperienceChange = (workIndex, field, value) => {
        const updatedWorkExperience = [...editedData.workExperience];
        updatedWorkExperience[workIndex][field] = value;
        setEditedData((prev) => ({ ...prev, workExperience: updatedWorkExperience }));
    };

    const addWorkExperience = () => {
        const newEntry = { placeOfWork: "", startYear: "", endYear: "" };
        setEditedData((prev) => ({
            ...prev,
            workExperience: [...(prev.workExperience || []), newEntry],
        }));
    };

    const removeWorkExperience = (workIndex) => {
        const updatedWorkExperience = editedData.workExperience.filter(
            (_, index) => index !== workIndex
        );
        setEditedData((prev) => ({ ...prev, workExperience: updatedWorkExperience }));
    };

    return (
        <div className="bg-gray-100 min-h-screen p-6">
            <h1 className="text-2xl font-bold text-[#172554] mb-6">Job Applications</h1>

            {/* Download to CSV */}
            <div className="mb-6">
                <CSVLink
                    data={csvData}
                    filename="job-applications.csv"
                    className="bg-green-500 text-white px-4 py-2 rounded mb-4 inline-flex items-center hover:bg-green-600"
                    data-tooltip-id="download-tooltip"
                >
                    <FaDownload className="mr-2" /> Download CSV
                </CSVLink>
                <Tooltip id="download-tooltip" content="Download all applications as CSV" />
            </div>

            {/* Applications Table */}
            <div className="overflow-x-auto bg-white shadow-md rounded-lg">
                <table className="min-w-full border-collapse border border-gray-300">
                    <thead className="bg-gray-200">
                        <tr>
                            <th className="border px-4 py-2 text-[#172554]">Name</th>
                            <th className="border px-4 py-2 text-[#172554]">Email</th>
                            <th className="border px-4 py-2 text-[#172554]">Work Experience</th>
                            <th className="border px-4 py-2 text-[#172554]">Submitted At</th>
                            <th className="border px-4 py-2 text-[#172554]">Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        {applications.map((application, index) => (
                            <tr
                                key={application.id}
                                className={index % 2 === 0 ? "bg-gray-100" : "bg-white"}
                            >
                                {editingIndex === index ? (
                                    <>
                                        <td className="border px-4 py-2">
                                            <input
                                                type="text"
                                                name="name"
                                                value={editedData.name}
                                                onChange={handleInputChange}
                                                className="w-full border rounded-md px-2 py-1"
                                            />
                                        </td>
                                        <td className="border px-4 py-2">
                                            <input
                                                type="email"
                                                name="email"
                                                value={editedData.email}
                                                onChange={handleInputChange}
                                                className="w-full border rounded-md px-2 py-1"
                                            />
                                        </td>
                                        <td className="border px-4 py-2">
                                            <div>
                                                {editedData.workExperience &&
                                                    editedData.workExperience.map(
                                                        (work, workIndex) => (
                                                            <div
                                                                key={workIndex}
                                                                className="space-y-2 mb-4"
                                                            >
                                                                <input
                                                                    type="text"
                                                                    placeholder="Place of Work"
                                                                    value={work.placeOfWork}
                                                                    onChange={(e) =>
                                                                        handleWorkExperienceChange(
                                                                            workIndex,
                                                                            "placeOfWork",
                                                                            e.target.value
                                                                        )
                                                                    }
                                                                    className="w-full border rounded-md px-2 py-1"
                                                                />
                                                                <input
                                                                    type="text"
                                                                    placeholder="Start Year"
                                                                    value={work.startYear}
                                                                    onChange={(e) =>
                                                                        handleWorkExperienceChange(
                                                                            workIndex,
                                                                            "startYear",
                                                                            e.target.value
                                                                        )
                                                                    }
                                                                    className="w-full border rounded-md px-2 py-1"
                                                                />
                                                                <input
                                                                    type="text"
                                                                    placeholder="End Year"
                                                                    value={work.endYear}
                                                                    onChange={(e) =>
                                                                        handleWorkExperienceChange(
                                                                            workIndex,
                                                                            "endYear",
                                                                            e.target.value
                                                                        )
                                                                    }
                                                                    className="w-full border rounded-md px-2 py-1"
                                                                />
                                                                <button
                                                                    onClick={() =>
                                                                        removeWorkExperience(
                                                                            workIndex
                                                                        )
                                                                    }
                                                                    className="text-red-500 hover:text-red-700"
                                                                    data-tooltip-id={`remove-tooltip-${workIndex}`}
                                                                >
                                                                    <FaTrash />
                                                                </button>
                                                                <Tooltip
                                                                    id={`remove-tooltip-${workIndex}`}
                                                                    content="Remove this work experience"
                                                                />
                                                            </div>
                                                        )
                                                    )}
                                                <button
                                                    onClick={addWorkExperience}
                                                    className="flex items-center bg-green-500 text-white px-4 py-2 rounded-md hover:bg-green-600 transition"
                                                    data-tooltip-id="add-tooltip"
                                                >
                                                    <FaPlus className="mr-2" /> Add Work Experience
                                                </button>
                                                <Tooltip id="add-tooltip" content="Add new work experience" />
                                            </div>
                                        </td>
                                        <td className="border px-4 py-2">{formatDateTime(editedData.submittedAt)}</td>
                                        <td className="border px-4 py-2 flex space-x-2">
                                            <button
                                                onClick={handleSave}
                                                className="bg-green-500 text-white px-4 py-2 rounded-md hover:bg-green-600 transition"
                                                data-tooltip-id="save-tooltip"
                                            >
                                                <FaSave />
                                            </button>
                                            <button
                                                onClick={handleCancel}
                                                className="bg-gray-500 text-white px-4 py-2 rounded-md hover:bg-gray-600 transition"
                                                data-tooltip-id="cancel-tooltip"
                                            >
                                                Cancel
                                            </button>
                                            <Tooltip id="save-tooltip" content="Save changes" />
                                            <Tooltip id="cancel-tooltip" content="Cancel editing" />
                                        </td>
                                    </>
                                ) : (
                                    <>
                                        <td className="border px-4 py-2">{application.name}</td>
                                        <td className="border px-4 py-2">{application.email}</td>
                                        <td className="border px-4 py-2">
                                            <ul>
                                                {application.workExperience &&
                                                    application.workExperience.map(
                                                        (work, workIndex) => (
                                                            <li key={workIndex}>
                                                                {`${work.placeOfWork} (${work.startYear} - ${work.endYear})`}
                                                            </li>
                                                        )
                                                    )}
                                            </ul>
                                        </td>
                                        <td className="border px-4 py-2">{formatDateTime(application.submittedAt)}</td>
                                        <td className="border px-4 py-2 flex space-x-2">
                                            <button
                                                onClick={() => handleEdit(index)}
                                                className="bg-blue-500 text-white px-4 py-2 rounded-md hover:bg-blue-600 transition"
                                                data-tooltip-id="edit-tooltip"
                                            >
                                                <FaEdit />
                                            </button>
                                            <Tooltip id="edit-tooltip" content="Edit this application" />
                                        </td>
                                    </>
                                )}
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        </div>
    );
};

export default JobApplications;
