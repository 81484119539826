import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { API_BASE_URL } from '../firebase/config';
import { NotificationManager } from 'react-notifications';

const RevenueHeadManager = ({ profileData }) => {
    const [profiles, setProfiles] = useState([]);
    const [selectedUserId, setSelectedUserId] = useState('');
    const [availableSubheads, setAvailableSubheads] = useState([]);
    const [assignedSubheads, setAssignedSubheads] = useState([]);
    const [selectedSubhead, setSelectedSubhead] = useState('');
    const [isLoading, setIsLoading] = useState(false);

    // Fetch all profiles
    const fetchProfiles = async () => {
        try {
            const response = await axios.get(`${API_BASE_URL}/dropdown/profile/oid?value=${profileData.organisation_id}`);
            if (response.data.status === 200) {
                return response.data.message;
            } else {
                NotificationManager.error('Failed to fetch profiles', 'Error');
                return [];
            }
        } catch (error) {
            NotificationManager.error('An error occurred while fetching profiles', 'Error');
            return [];
        }
    };

    // Fetch available subheads for a user
    const fetchAvailableSubheads = async () => {
        try {
            const response = await axios.get(`${API_BASE_URL}/dropdown/subhead/oid?value=${profileData.organisation_id}`);
            if (response.data.status === 200) {
                return response.data.message;
            } else {
                NotificationManager.error('Failed to fetch subheads', 'Error');
                return [];
            }
        } catch (error) {
            NotificationManager.error('An error occurred while fetching subheads', 'Error');
            return [];
        }
    };

    // Fetch assigned subheads for a user
    const fetchRevenues = async (userId) => {
        try {
            const response = await axios.get(`${API_BASE_URL}/dropdown/revenuehead/profileId?value=${userId}`);
            if (response.data.status === 200) {
                return response.data.message;
            } else {
                NotificationManager.error('Failed to fetch assigned subheads', 'Error');
                return [];
            }
        } catch (error) {
            NotificationManager.error('An error occurred while fetching assigned subheads', 'Error');
            return [];
        }
    };

    // Assign subhead to user
    const assignSubhead = async (userId, subheadId) => {
        try {
            const response = await axios.post(`${API_BASE_URL}/revenueheads/create`, { profileId: userId, subheadId, uid: '07000000001' });
            if (response.data.status === 200) {
                NotificationManager.success('Subhead assigned successfully', 'Success');
                return response.data.message;
            } else {
                NotificationManager.error(response.data.message, 'Error');
                return [];
            }
        } catch (error) {
            NotificationManager.error('An error occurred while assigning the subhead', 'Error');
            return [];
        }
    };

    // Unassign subhead from user
    const unassignSubhead = async (subheadId) => {
        try {
            const response = await axios.post(`${API_BASE_URL}/revenueheads/delete`, { recordId: subheadId });
            if (response.data.status === 200) {
                NotificationManager.success('Subhead unassigned successfully', 'Success');
                return response.data.message;
            } else {
                NotificationManager.error(response.data.message, 'Error');
                return [];
            }
        } catch (error) {
            NotificationManager.error('An error occurred while unassigning the subhead', 'Error');
            return [];
        }
    };

    useEffect(() => {
        const fetchAllProfiles = async () => {
            setIsLoading(true);
            const data = await fetchProfiles();
            setProfiles(data);
            setIsLoading(false);
        };
        fetchAllProfiles();
    }, []);

    useEffect(() => {
        if (!selectedUserId) return;

        const fetchData = async () => {
            setIsLoading(true);
            const subheads = await fetchAvailableSubheads();
            const revenues = await fetchRevenues(selectedUserId);
            setAvailableSubheads(subheads);
            setAssignedSubheads(revenues);
            setIsLoading(false);
        };

        fetchData();
    }, [selectedUserId]);

    const handleAssign = async () => {
        setIsLoading(true);
        await assignSubhead(selectedUserId, selectedSubhead);
        setSelectedSubhead('');
        const subheads = await fetchAvailableSubheads();
        const revenues = await fetchRevenues(selectedUserId);
        setAvailableSubheads(subheads);
        setAssignedSubheads(revenues);
        setIsLoading(false);
    };

    const handleUnassign = async (subheadId) => {
        setIsLoading(true);
        await unassignSubhead(subheadId);
        const subheads = await fetchAvailableSubheads();
        const revenues = await fetchRevenues(selectedUserId);
        setAvailableSubheads(subheads);
        setAssignedSubheads(revenues);
        setIsLoading(false);
    };

    return (
        <div className="container mx-auto p-4 bg-gray-50 min-h-screen">
            <h1 className="text-xl font-bold text-gray-800 mb-6 text-center">Revenue Head Manager</h1>

            {isLoading && <div className="text-sm text-blue-600 text-center mb-4">Loading...</div>}

            <div className="grid grid-cols-1 lg:grid-cols-2 gap-6">
                {/* User Selection */}
                <div className="bg-white shadow-sm rounded-md p-4">
                    <h2 className="text-sm font-semibold text-gray-700 mb-3">Select User</h2>
                    <select
                        className="w-full border border-gray-300 rounded-md p-2 text-sm text-gray-700 focus:outline-none focus:ring focus:ring-blue-300"
                        onChange={(e) => setSelectedUserId(e.target.value)}
                        value={selectedUserId}
                        disabled={isLoading}
                    >
                        <option value="">-- Select User --</option>
                        {profiles.map((profile) => (
                            <option key={profile.id} value={profile.id}>
                                {profile.name}
                            </option>
                        ))}
                    </select>
                </div>

                {/* Available Subheads */}
                <div className="bg-white shadow-sm rounded-md p-4">
                    <h2 className="text-sm font-semibold text-gray-700 mb-3">Available Subheads</h2>
                    <select
                        className="w-full border border-gray-300 rounded-md p-2 text-sm text-gray-700 focus:outline-none focus:ring focus:ring-blue-300 mb-3"
                        onChange={(e) => setSelectedSubhead(e.target.value)}
                        value={selectedSubhead}
                        disabled={isLoading || !selectedUserId}
                    >
                        <option value="">-- Select Subhead --</option>
                        {availableSubheads.map((subhead) => (
                            <option key={subhead.id} value={subhead.id}>
                                {subhead.subhead}
                            </option>
                        ))}
                    </select>
                    <button
                        onClick={handleAssign}
                        className="w-full bg-blue-500 hover:bg-blue-600 text-white rounded-md p-2 text-sm font-semibold transition disabled:bg-gray-300"
                        disabled={isLoading || !selectedSubhead || !selectedUserId}
                    >
                        Assign Subhead
                    </button>
                </div>
            </div>

            {/* Assigned Subheads */}
            <div className="mt-6 bg-white shadow-sm rounded-md p-4">
                <h2 className="text-sm font-semibold text-gray-700 mb-3">Assigned Subheads</h2>
                {assignedSubheads.length === 0 ? (
                    <p className="text-sm text-gray-600">No subheads assigned yet.</p>
                ) : (
                    <ul className="space-y-2">
                        {assignedSubheads.map((subhead) => (
                            <li
                                key={subhead.id}
                                className="flex justify-between items-center border-b pb-1"
                            >
                                <span className="text-sm text-gray-800">{subhead.subhead}</span>
                                <button
                                    onClick={() => handleUnassign(subhead.id)}
                                    className="bg-red-500 hover:bg-red-600 text-white rounded-md px-2 py-1 text-xs font-semibold transition disabled:bg-gray-300"
                                    disabled={isLoading}
                                >
                                    Unassign
                                </button>
                            </li>
                        ))}
                    </ul>
                )}
            </div>
        </div>
    );
};

export default RevenueHeadManager;
