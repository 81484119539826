import React from 'react';
import { Link } from 'react-router-dom'; // Import Link for navigation
import heroImage from '../img/hero2-removebg-preview.png'
const Hero = () => {
  return (
    <section id="hero" className="bg-blue-950 bg-no-repeat text-white text-center py-20" style={{ backgroundImage: `url(${heroImage})` }}>
      <div className="container mx-auto">
        <h1 className="text-4xl font-semibold">Welcome to Benue State Bureau for Education Quality Assurance</h1>
        <p className="mt-4 font-semibold">Ensuring Quality Education for All</p>
        <a href="#about" className="mt-6 inline-block bg-red-500 text-white py-2 px-4 rounded">Learn More</a>
        <br className="block md:hidden" />

        {/* e-Dossier Sliding Link */}
        <a
          href="https://beqa.ng"
          target="_blank"
          rel="noopener noreferrer"
          className="block md:hidden mt-4 inline-block bg-[#1ABC9C] text-white font-bold py-2 px-4 rounded-lg hover:bg-[#16A085] transition duration-200 animate-slide"
        >
          Visit Our e-Dossier Platform
        </a>
        {/* Navigation link to Application Submission Form */}
        <div className="block md:hidden mt-6">
          <Link
            to="/application-form"
            className="text-red-500 underline hover:text-red-500 transition duration-200"
          >
          JOB VACANCIES
          </Link>
        </div>
      </div>

    </section>
  );
};

export default Hero;
