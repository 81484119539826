import React, { useState } from "react";
import { getFirestore, collection, addDoc } from "firebase/firestore";
import app from "../firebase/firebase";
import { Link } from "react-router-dom";
import logo from "../img/logo.png"; // Adjust path if necessary
import Guidelines from "./Guidelines";
import { FaBriefcase, FaPlusCircle, FaTrash } from "react-icons/fa";

const db = getFirestore(app);

const benueLGAs = {
    "ado": "1BN",
    "agatu": "2BN",
    "apa": "3BN",
    "buruku": "4BN",
    "gboko": "5BN",
    "guma": "6BN",
    "gwer East": "7BN",
    "gwer West": "8BN",
    "katsina-Ala": "9BN",
    "konshisha": "10BN",
    "kwande": "11BN",
    "logo": "12BN",
    "makurdi": "13BN",
    "obi": "14BN",
    "ogbadibo": "15BN",
    "ohimini": "16BN",
    "oju": "17BN",
    "okpokwu": "18BN",
    "otukpo": "19BN",
    "tarka": "20BN",
    "ukum": "21BN",
    "ushongo": "22BN",
    "vandeikya": "23BN"
};

const qualifications = [
    "First Degree in Education",
    "Postgraduate Diploma in Education (PGDE)",
    "Master’s Degree in Education",
    "PhD in Education",
];

const states = [
    "Abia",
    "Adamawa",
    "Akwa Ibom",
    "Anambra",
    "Bauchi",
    "Bayelsa",
    "Benue",
    "Borno",
    "Cross River",
    "Delta",
    "Ebonyi",
    "Edo",
    "Ekiti",
    "Enugu",
    "Gombe",
    "Imo",
    "Jigawa",
    "Kaduna",
    "Kano",
    "Katsina",
    "Kebbi",
    "Kogi",
    "Kwara",
    "Lagos",
    "Nasarawa",
    "Niger",
    "Ogun",
    "Ondo",
    "Osun",
    "Oyo",
    "Plateau",
    "Rivers",
    "Sokoto",
    "Taraba",
    "Yobe",
    "Zamfara",
    "Federal Capital Territory (FCT)"
];

const gradeLevels = [
    "GL 14",
    "GL 15",
    "GL 16",
    "GL 17",
];

const presentStations = [
    "Akperan Orshi College of Agriculture, Yandev",
    "Benue Links Nigeria Limited",
    "Benue State AIDS Control Agency",
    "Benue State Agency for Adult and Non-Formal Education",
    "Benue State Agency for Community and Social Development",
    "Benue State Agricultural and Rural Development Authority",
    "Benue State Broadcasting Service",
    "Benue State Council for Arts and Culture",
    "Benue State Environmental and Sanitation Agency",
    "Benue State Fire Service",
    "Benue State Geographic Information Service",
    "Benue State Housing Agency",
    "Benue State Information Technology Development Agency",
    "Benue State Internal Revenue Service",
    "Benue State Investment and Property Company",
    "Benue State Library Board",
    "Benue State Planning Commission",
    "Benue State Polytechnic, Ugbokolo",
    "Benue State Primary Health Care Board",
    "Benue State Scholarship Board",
    "Benue State Sports Council",
    "Benue State Teaching Service Board",
    "Benue State University, Makurdi",
    "Benue State Urban Development Board",
    "Benue State Water Board",
    "Bureau of Lands and Survey",
    "Bureau of Local Government and Chieftaincy Affairs",
    "Bureau of Rural Development and Cooperatives",
    "College of Education, Katsina-Ala",
    "College of Education, Oju",
    "Ministry of Agriculture and Food Security",
    "Ministry of Arts, Culture, and Tourism",
    "Ministry of Communications and Digital Economy",
    "Ministry of Cooperative and Rural Development",
    "Ministry of Education and Knowledge Management",
    "Ministry of Finance, Budget, and Economic Planning",
    "Ministry of Health and Human Services",
    "Ministry of Housing and Urban Development",
    "Ministry of Humanitarian Affairs and Disaster Management",
    "Ministry of Industry, Trade, and Investment",
    "Ministry of Information, Culture, and Tourism",
    "Ministry of Justice and Public Order",
    "Ministry of Lands, Survey, and Solid Minerals",
    "Ministry of Power, Renewable Energy, and Transport",
    "Ministry of Science and Technology",
    "Ministry of Water Resources, Environment, and Climate Change",
    "Ministry of Women Affairs and Social Development",
    "Ministry of Works, Housing, and Urban Development",
    "Ministry of Youths, Sports, and Creativity",
    "Office of the Head of Service",
    "University of Agriculture, Makurdi",
];

const ApplicationForm = () => {
    const [formData, setFormData] = useState({
        name: "",
        email: "",
        phone: "",
        dateOfBirth: "",
        stateOfOrigin: "",
        lgaOfOrigin: "",
        localGovernment: "",
        qualification: "",
        personnelSubHeadNo: "",
        gradeLevel: "",
        presentStation: "",
        ictKnowledge: "",
        teachingMethodology: "",
        financialManagement: "",
        curriculumKnowledge: "",
        residency: "",
    });

    const [workExperienceData, setWorkExperienceData] = useState([
        { placeOfWork: "", startYear: "", endYear: "" },
    ]);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({ ...prevData, [name]: value }));
    };

    const handleWorkExperienceChange = (index, field, value) => {
        const updatedWorkExperience = [...workExperienceData];
        updatedWorkExperience[index][field] = value;
        setWorkExperienceData(updatedWorkExperience);
    };

    const addWorkExperience = () => {
        setWorkExperienceData([
            ...workExperienceData,
            { placeOfWork: "", startYear: "", endYear: "" },
        ]);
    };

    const removeWorkExperience = (index) => {
        const updatedWorkExperience = workExperienceData.filter((_, i) => i !== index);
        setWorkExperienceData(updatedWorkExperience);
    };

    const validateWorkExperience = () => {
        return workExperienceData.every(
            (entry) =>
                entry.placeOfWork.trim() !== "" &&
                entry.startYear > 0 &&
                entry.endYear > entry.startYear
        );
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (!validateWorkExperience()) {
            alert("Please fill out all work experience fields correctly.");
            return;
        }

        try {
            const gmtPlusOneTime = new Date().toLocaleString("en-US", {
                timeZone: "Africa/Lagos",
            });

            await addDoc(collection(db, "applications"), {
                ...formData,
                workExperience: workExperienceData,
                submittedAt: new Date(gmtPlusOneTime),
            });

            alert("Application submitted successfully!");
            setFormData({
                name: "",
                email: "",
                phone: "",
                dateOfBirth: "",
                stateOfOrigin: "",
                lgaOfOrigin: "",
                localGovernment: "",
                qualification: "",
                personnelSubHeadNo: "",
                gradeLevel: "",
                presentStation: "",
                ictKnowledge: "",
                teachingMethodology: "",
                financialManagement: "",
                curriculumKnowledge: "",
                residency: "",
            });
            setWorkExperienceData([{ placeOfWork: "", startYear: "", endYear: "" }]);
        } catch (error) {
            console.error("Error submitting application:", error);
            alert(`Submission failed: ${error.message}`);
        }
    };

    return (
        <div className="bg-gradient-to-b from-gray-50 to-gray-100 min-h-screen flex flex-col items-center">
            {/* Logo and Navigation */}
            <header className="w-full bg-[#172554] shadow-md py-4 mb-8">
                <div className="container mx-auto px-4 flex justify-between items-center">
                    <img src={logo} alt="Logo" className="h-12" />
                    <nav>
                        <Link to="/" className="text-blue-300 hover:text-blue-100 font-medium">Home</Link>
                    </nav>
                </div>
            </header>

            {/* Guidelines Section */}
            <Guidelines />

            {/* Form Section */}
            <div className="container mx-auto bg-white shadow-lg rounded-lg p-6 w-full max-w-3xl">
                <h2 className="text-3xl font-bold text-center text-[#172554] mb-6">Application Form</h2>
                <form onSubmit={handleSubmit} className="space-y-6">
                    {/* Personal Information Fields */}
                    <div>
                        <label className="block text-[#172554] font-bold">Full Name:</label>
                        <input
                            type="text"
                            name="name"
                            value={formData.name}
                            onChange={handleChange}
                            required
                            className="w-full border p-2 rounded-md shadow-sm"
                        />
                    </div>
                    <div>
                        <label className="block text-[#172554] font-bold">Personnel Sub-Head No.:</label>
                        <input
                            type="text"
                            name="personnelSubHeadNo"
                            value={formData.personnelSubHeadNo}
                            onChange={handleChange}
                            required
                            className="w-full border p-2 rounded-md shadow-sm"
                        />
                    </div>
                    <div>
                        <label className="block text-[#172554] font-bold">Date of Birth:</label>
                        <input
                            type="date"
                            name="dateOfBirth"
                            value={formData.dateOfBirth}
                            onChange={handleChange}
                            required
                            className="w-full border p-2 rounded-md shadow-sm"
                        />
                    </div>
                    <div>
                        <label className="block text-[#172554] font-bold">State of Origin:</label>
                        <select
                            name="stateOfOrigin"
                            value={formData.stateOfOrigin}
                            onChange={handleChange}
                            required
                            className="w-full border p-2 rounded-md shadow-sm"
                        >
                            <option value="">Select State</option>
                            {states.map((state, index) => (
                                <option key={index} value={state}>{state}</option>
                            ))}
                        </select>
                    </div>
                    <div>
                        <label className="block text-[#172554] font-bold">LGA of Origin:</label>
                        <select
                            name="lgaOfOrigin"
                            value={formData.lgaOfOrigin}
                            onChange={handleChange}
                            required
                            className="w-full border p-2 rounded-md shadow-sm"
                        >
                            <option value="">Select LGA</option>
                            {Object.entries(benueLGAs).map(([lga, code]) => (
                                <option key={code} value={code}>{lga}</option>
                            ))}
                        </select>
                    </div>
                    <div>
                        <label className="block text-[#172554] font-bold">LGA to Work:</label>
                        <select
                            name="localGovernment"
                            value={formData.localGovernment}
                            onChange={handleChange}
                            required
                            className="w-full border p-2 rounded-md shadow-sm"
                        >
                            <option value="">Select LGA</option>
                            {Object.entries(benueLGAs).map(([lga, code]) => (
                                <option key={code} value={code}>{lga}</option>
                            ))}
                        </select>
                    </div>
                    <div>
                        <label className="block text-[#172554] font-bold">Grade Level:</label>
                        <select
                            name="gradeLevel"
                            value={formData.gradeLevel}
                            onChange={handleChange}
                            required
                            className="w-full border p-2 rounded-md shadow-sm"
                        >
                            <option value="">Select Grade Level</option>
                            {gradeLevels.map((level, index) => (
                                <option key={index} value={level}>
                                    {level}
                                </option>
                            ))}
                        </select>
                    </div>

                    <div>
                        <label className="block text-[#172554] font-bold">Present Station (MDA):</label>
                        <select
                            name="presentStation"
                            value={formData.presentStation}
                            onChange={handleChange}
                            required
                            className="w-full border p-2 rounded-md shadow-sm"
                        >
                            <option value="">Select Present Station</option>
                            {presentStations.map((station, index) => (
                                <option key={index} value={station}>
                                    {station}
                                </option>
                            ))}
                        </select>
                    </div>

                    <div>
                        <label htmlFor="email" className="block text-[#172554] font-bold">Email:</label>
                        <input
                            type="email"
                            id="email"
                            name="email"
                            value={formData.email}
                            onChange={handleChange}
                            required
                            className="w-full border border-gray-300 rounded-md p-2 shadow-sm focus:ring focus:ring-blue-200"
                        />
                    </div>

                    <div>
                        <label htmlFor="phone" className="block text-[#172554] font-bold">Phone Number:</label>
                        <input
                            type="tel"
                            id="phone"
                            name="phone"
                            value={formData.phone}
                            onChange={handleChange}
                            required
                            className="w-full border border-gray-300 rounded-md p-2 shadow-sm focus:ring focus:ring-blue-200"
                        />
                    </div>

                    <div>
                        <label htmlFor="qualification" className="block text-[#172554] font-bold">Highest Qualification:</label>
                        <select
                            id="qualification"
                            name="qualification"
                            value={formData.qualification}
                            onChange={handleChange}
                            required
                            className="w-full border border-gray-300 rounded-md p-2 shadow-sm focus:ring focus:ring-blue-200"
                        >
                            <option value="">Select your Qualification</option>
                            {qualifications.map((qualification, index) => (
                                <option key={index} value={qualification}>{qualification}</option>
                            ))}
                        </select>
                    </div>

                    {/* Work Experience Table */}
                    <div>
                        <h3 className="text-lg font-bold text-[#172554] flex items-center mb-4">
                            <FaBriefcase className="text-blue-500 mr-2" />
                            Work Experience
                        </h3>
                        <div className="overflow-x-auto">
                            <table className="min-w-full table-auto border border-gray-300">
                                <thead className="bg-gray-200">
                                    <tr>
                                        <th className="border px-4 py-2 text-[#172554]">Place of Work</th>
                                        <th className="border px-4 py-2 text-[#172554]">Start Year</th>
                                        <th className="border px-4 py-2 text-[#172554]">End Year</th>
                                        <th className="border px-4 py-2 text-[#172554]">Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {workExperienceData.map((entry, index) => (
                                        <tr key={index} >
                                            <td className="border px-4 py-2">
                                                <input
                                                    type="text"
                                                    value={entry.placeOfWork}
                                                    onChange={(e) => handleWorkExperienceChange(index, 'placeOfWork', e.target.value)}
                                                    className="w-full flex-grow border border-gray-300 rounded-md px-3 py-1 focus:ring focus:ring-blue-200"
                                                />
                                            </td>
                                            <td className="border px-4 py-2">
                                                <input
                                                    type="number"
                                                    value={entry.startYear}
                                                    onChange={(e) => handleWorkExperienceChange(index, 'startYear', e.target.value)}
                                                    className="w-full flex-grow border border-gray-300 rounded-md px-3 py-1 focus:ring focus:ring-blue-200"
                                                />
                                            </td>
                                            <td className="border px-4 py-2">
                                                <input
                                                    type="number"
                                                    value={entry.endYear}
                                                    onChange={(e) => handleWorkExperienceChange(index, 'endYear', e.target.value)}
                                                    className="w-full flex-grow border border-gray-300 rounded-md px-3 py-1 focus:ring focus:ring-blue-200"
                                                />
                                            </td>
                                            <td className="border px-4 py-2 text-center">
                                                <button
                                                    type="button"
                                                    onClick={() => removeWorkExperience(index)}
                                                    className="text-red-500 hover:text-red-700 flex items-center mt-2"
                                                >
                                                    <FaTrash className="mr-1" />
                                                </button>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                        <button
                            type="button"
                            onClick={addWorkExperience}
                            className="flex items-center justify-center bg-gradient-to-r from-green-500 to-green-700 text-white font-semibold py-2 px-4 rounded-md hover:shadow-lg hover:from-green-400 hover:to-green-600 transition duration-200"
                        >
                            <FaPlusCircle className="mr-2" />
                        </button>
                    </div>

                    {/* Additional form fields */}
                    <div>
                        <label htmlFor="ictKnowledge" className="block text-[#172554] font-bold">Knowledge of ICT:</label>
                        <select
                            id="ictKnowledge"
                            name="ictKnowledge"
                            value={formData.ictKnowledge}
                            onChange={handleChange}
                            required
                            className="w-full border border-gray-300 rounded-md p-2 shadow-sm focus:ring focus:ring-blue-200"
                        >
                            <option value="">Select</option>
                            <option value="Yes">Yes</option>
                            <option value="No">No</option>
                        </select>
                    </div>

                    <div>
                        <label htmlFor="teachingMethodology" className="block text-[#172554] font-bold">Expertise in Teaching Methodology:</label>
                        <textarea
                            id="teachingMethodology"
                            name="teachingMethodology"
                            rows="3"
                            value={formData.teachingMethodology}
                            onChange={handleChange}
                            required
                            className="w-full border border-gray-300 rounded-md p-2 shadow-sm focus:ring focus:ring-blue-200"
                        ></textarea>
                    </div>

                    <div>
                        <label htmlFor="financialManagement" className="block text-[#172554] font-bold">Prudent Financial Management Skills:</label>
                        <textarea
                            id="financialManagement"
                            name="financialManagement"
                            rows="3"
                            value={formData.financialManagement}
                            onChange={handleChange}
                            required
                            className="w-full border border-gray-300 rounded-md p-2 shadow-sm focus:ring focus:ring-blue-200"
                        ></textarea>
                    </div>

                    <div>
                        <label htmlFor="curriculumKnowledge" className="block text-[#172554] font-bold">Knowledge of Curriculum:</label>
                        <textarea
                            id="curriculumKnowledge"
                            name="curriculumKnowledge"
                            rows="3"
                            value={formData.curriculumKnowledge}
                            onChange={handleChange}
                            required
                            className="w-full border border-gray-300 rounded-md p-2 shadow-sm focus:ring focus:ring-blue-200"
                        ></textarea>
                    </div>

                    <div>
                        <label htmlFor="residency" className="block text-[#172554] font-bold">Are you willing to reside in your chosen Local Government Area?</label>
                        <select
                            id="residency"
                            name="residency"
                            value={formData.residency}
                            onChange={handleChange}
                            required
                            className="w-full border border-gray-300 rounded-md p-2 shadow-sm focus:ring focus:ring-blue-200"
                        >
                            <option value="">Select</option>
                            <option value="Yes">Yes</option>
                            <option value="No">No</option>
                        </select>
                    </div>

                    <button
                        type="submit"
                        className="bg-[#172554] text-white py-2 px-4 mt-5 border-none rounded-md w-full cursor-pointer hover:bg-[#12429a] transition duration-200"
                    >
                        Submit Application
                    </button>
                </form>
            </div>
        </div>
    );
};

export default ApplicationForm;
