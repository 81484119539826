import React from "react";

const Guidelines = () => {
    return (
        <div className="container mx-auto bg-white shadow-lg rounded-lg p-6 w-full max-w-3xl mb-6">
            <h2 className="text-2xl text-center font-bold text-[#172554] mb-4">Guidelines</h2>
            <div className="text-gray-700 space-y-2">
                <p className="text-center text-[#239e53] font-bold">INTERNAL VACANCIES</p>
                <p className="font-bold text-center">POSITIONS – 1 FOR EACH OF THE 23 LOCAL GOVERNMENT AREAS</p>
                <p className="text-l text-center text-[#f10404]">Applicants must be employees of Benue State Government who are willing to be deployed to BEQA</p>
                <p className="text-l text-center text-[#239e53] font-bold">TITLE</p>
                <p className="font-bold text-center">LOCAL GOVERNMENT CHIEF EDUCATION QUALITY ASSURANCE OFFICERS (LGCEQAO)</p>
                <p>In the quest to effectively and efficiently deliver on her mandate to the state education sector as envisioned by His Excellency, the Executive Governor of Benue State, Rev. Fr. Dr Hyacinth Iormem Alia; The Bureau for Education Quality Assurance seeks outstanding candidates for employment to fill existing vacancies in the position mentioned above. Preference will be given to candidates who are vastly experienced as teachers and are familiar with organizational turnaround strategy, educational management, data analytics and the importance of paying attention to details.</p>
                <p className="font-bold">KEY RESPONSIBILITIES:</p>
                <ul className="list-disc list-inside">
                    <li>Represent the DG at the various Local Government Areas under their purview</li>
                    <li>Ensure monitoring and Evaluation of Schools at the Local Government Areas</li>
                    <li>Mobilize all schools below tertiary level for payment of statutory levies and dues</li>
                    <li>Assist in the inspection of Schools for establishment purposes</li>
                    <li>Ensure the generation of accurate and reliable up-to-date data from schools in the Local Government Areas</li>
                    <li>Coordinate all Benue State Bureau for Education Quality Assurance activities in the Local Government Areas</li>
                    <li>Draw itinerary of monitoring and evaluation for schools in the Local Government Area</li>
                    <li>Report progress of work done and challenges to the Director General of the Bureau</li>
                    <li>Shall be part of all combined Whole School Evaluation (WSE), monitoring visit to schools in the Local Government Area</li>
                    <li>Support Head Teachers/Principals through continuous mentoring, coaching, and meetings in their role as leaders and managers of teaching</li>
                    <li>Support schools in conducting school Self-Evaluations and School Development Plans</li>
                </ul>
                <p className="font-bold">REQUIREMENTS:</p>
                <p className="font-bold">Those willing to apply for the position of the Local Government Chief Education Quality Assurance Officer (LGEQAO) must possess the following:</p>
                <p className="font-bold">A. QUALIFICATION:</p>
                <ul className="list-disc list-inside">
                    <li>First degree in education or PGDE, master’s degree will confer an added advantage.</li>
                </ul>
                <p className="font-bold">B. WORK EXPERIENCE</p>
                <ul className="list-disc list-inside">
                    <li>Grade Level 14 and above.</li>
                    <li>At least 15 years’ experience as a Class Teacher/Evaluator</li>
                    <li>Must have been Area Education Officer, Senior Evaluator, Principal, Vice Principal, Head Teacher and or Assistant Head Teacher</li>
                    <li>A good knowledge of ICT and/or Computer related competence will be an added advantage.</li>
                </ul>
                <p className="text-l font-bold">APPLICANTS ARE TO NOTE:</p>
                <p className="font-bold" >In addition to the aforementioned requirements, applicants should also have:</p>
                <ul className="list-disc list-inside">
                    <li>Strong spoken and written communication skills in English, as assessed by a written examination.</li>
                    <li>Proficient in computer appreciation and fully conversant with the use of a variety of computer applications.</li>
                    <li>Prudent financial management</li>
                    <li>Expertise in a range of teaching methodologies.</li>
                    <li>Ability to identify effective teaching practices.</li>
                    <li>Good knowledge of the curriculum and capacity to stay up to date with changes.</li>
                    <li>Capacity to identify areas of need in schools.</li>
                    <li>Capacity to offer some solution to areas of school needs.</li>
                    <li>Ability to facilitate effective training workshops.</li>
                    <li>Ability to plan.</li>
                </ul>
                <p><span className="font-bold mt-8">Closing date:</span> 24th January, 2025</p>
                <p className="font-bold">Nota Bene:</p>
                <ul className="list-disc list-inside mb-4">
                    <li>Applicants must be willing to reside in their chosen Local Government Area of Application</li>
                    <li>Only shortlisted candidates will be contacted for written examination and if successful, interviews.</li>
                </ul>
                <p className="font-bold">Terna Francis, PhD, KSM</p>
                <p>Director General</p>
            </div>
        </div>
    );
};

export default Guidelines;
