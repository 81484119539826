import React, { useState } from "react";
import { Link, useLocation } from "react-router-dom";
import Logo from "../img/logo192.png";
import ProfileDropdown from "./ProfileDropdown";
import Sidebar from "./Sidebar";
import Dashboard from "./Dashboard";
import GradingForm from "./GradingForm";
import SchoolReport from "./SchoolReport";
import TransactionsReport from "./TransactionsReport";
import TransactionSummaryReport from "./TransactionSummaryReport";
import RemitanceReport from "./RemitanceReport";
import MonthlyRevenueReport from "./MonthlyRevenueReport";
import MonthlyProfileReport from "./MonthlyProfileReport";
import MonthlyStationReport from "./MonthlyStationReport";
import RevenueHeadManager from "./RevenueHeadManager";
import SubheadsManager from "./SubheadsManager";
import JobApplications from "./JobApplications"; // Import JobApplications component

const AdminPanel = () => {
  const location = useLocation();
  const profileData = location.state?.profileData;

  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [activeMenu, setActiveMenu] = useState("dashboard");
  const [settingsOpen, setSettingsOpen] = useState(false);
  const [dataCaptureOpen, setDataCaptureOpen] = useState(false);
  const [reportsOpen, setReportsOpen] = useState(false);

  // Toggle Sidebar Visibility
  const handleToggleSidebar = () => setIsSidebarOpen(!isSidebarOpen);

  // Handle Menu Click
  const handleMenuClick = (menu) => {
    setActiveMenu(menu);
    if (isSidebarOpen) setIsSidebarOpen(false); // Collapse sidebar in mobile view
  };

  // Submenu Toggles
  const toggleDataCapture = () => setDataCaptureOpen(!dataCaptureOpen);
  const toggleReports = () => setReportsOpen(!reportsOpen);

  return (
    <div className="min-h-screen flex flex-col bg-[#ECF0F1]">
      {/* Navbar */}
      <nav className="bg-[#2C3E50] text-white border-b border-[#1abc9c] fixed z-30 w-full">
        <div className="px-3 py-3 lg:px-5 lg:pl-3">
          <div className="flex items-center justify-between">
            <div className="flex items-center justify-start">
              {/* Sidebar Toggle Button */}
              <button
                id="toggleSidebarMobile"
                aria-expanded={isSidebarOpen}
                onClick={handleToggleSidebar}
                className="lg:hidden mr-2 text-white hover:text-[#1ABC9C]"
              >
                <svg
                  className="w-6 h-6"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M2 4h16v2H2V4zm0 5h16v2H2V9zm0 5h16v2H2v-2z"
                    clipRule="evenodd"
                    fillRule="evenodd"
                  ></path>
                </svg>
              </button>

              {/* Logo and Brand */}
              <Link to="/" className="text-xl font-bold flex items-center lg:ml-2.5">
                <img src={Logo} className="h-8 mr-2" alt="Logo" />
                <span>BEQA</span>
              </Link>
            </div>

            {/* Profile Dropdown */}
            <ProfileDropdown profile={profileData} />
          </div>
        </div>
      </nav>

      {/* Main Content */}
      <div className="flex overflow-hidden h-screen pt-16">
        {/* Sidebar */}
        <aside
          id="sidebar"
          className={`${isSidebarOpen ? "block fixed inset-0 z-40" : "hidden"
            } lg:block lg:relative lg:z-30 w-60 bg-gray-800 text-white border-r border-gray-700 h-full`}
          aria-label="Sidebar"
        >
          <Sidebar
            isSidebarOpen={isSidebarOpen}
            activeMenu={activeMenu}
            handleMenuClick={handleMenuClick}
            dataCaptureOpen={dataCaptureOpen}
            toggleDataCapture={toggleDataCapture}
            reportsOpen={reportsOpen}
            toggleReports={toggleReports}
            settingsOpen={settingsOpen}
            toggleSettings={() => setSettingsOpen(!settingsOpen)}
          />
        </aside>

        {/* Backdrop for Mobile Sidebar */}
        {isSidebarOpen && (
          <div
            className="fixed inset-0 bg-black bg-opacity-50 z-30 lg:hidden"
            onClick={() => setIsSidebarOpen(false)} // Close sidebar on backdrop click
          ></div>
        )}

        {/* Main Content Area */}
        <div className="w-full overflow-y-auto">
          <main className="flex-grow p-4">
            {(() => {
              switch (activeMenu) {
                case "dashboard":
                  return <Dashboard profileData={profileData} />;
                case "grading":
                  return <GradingForm />;
                case "school-report":
                  return <SchoolReport />;
                case "transaction-report":
                  return <TransactionsReport profileData={profileData} />;
                case "transaction-summary":
                  return <TransactionSummaryReport profileData={profileData} />;
                case "remittance-summary":
                  return <RemitanceReport profileData={profileData} />;
                case "revenue-performance":
                  return <MonthlyRevenueReport profileData={profileData} />;
                case "profile-performance":
                  return <MonthlyProfileReport profileData={profileData} />;
                case "station-performance":
                  return <MonthlyStationReport profileData={profileData} />;
                case "subheads":
                  return <SubheadsManager profileData={profileData} />;
                case "revenue":
                  return <RevenueHeadManager profileData={profileData} />;
                case "jobApplications": // Add JobApplications case
                  return <JobApplications />;
                default:
                  return <div className="text-gray-500">Select a menu option</div>;
              }
            })()}
          </main>
        </div>
      </div>
    </div>
  );
};

export default AdminPanel;
